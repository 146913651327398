



















import { Component, Mixins, Prop } from 'vue-property-decorator'
import { View } from 'client-website-ts-library/plugins'

@Component({
  components: {
    VNodes: {
      functional: true,
      render: (h: any, ctx: any) => ctx.props.vnodes,
    },
  },
})
export default class SideBySideSection extends Mixins(View) {
  @Prop({ default: 'left' })
  public imagesPosition!: 'left' | 'right'

  @Prop({ default: 'transparent' })
  private readonly backgroundColour!: string
}
