


















import { Component, Mixins, Prop } from 'vue-property-decorator'
import { View } from 'client-website-ts-library/plugins'

@Component
export default class WindowSection extends Mixins(View) {
  @Prop({ default: 'transparent' })
  private readonly backgroundColour!: string

  @Prop({ default: 'transparent' })
  private readonly bottomBackgroundColour!: string
}
