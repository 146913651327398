



















import { Component, Mixins } from 'vue-property-decorator'
import { View } from 'client-website-ts-library/plugins'

@Component({
  components: {
    VNodes: {
      functional: true,
      render: (h: any, ctx: any) => ctx.props.vnodes,
    },
  },
})
export default class TopBottomSection extends Mixins(View) {
}
