








































































































import { Component, Mixins } from 'vue-property-decorator'

import { ListingFilter, TestimonialFilter } from 'client-website-ts-library/filters'
import { Listing, ListingCategory, ListingStatus, Office, Testimonial, WebsiteLevel } from 'client-website-ts-library/types'
import { API, Config } from 'client-website-ts-library/services'
import { View } from 'client-website-ts-library/plugins'

import Offices from '@/components/Offices.vue'
import ListingCardCycler from '@/components/Cycler/ListingCardCycler.vue'
import LinkButton from '@/components/UI/LinkButton.vue'
import NavigationLink from '@/components/Common/NavigationLink.vue'
import GoogleReviews from '@/components/GoogleReviews.vue'
import TopBottomSection from '@/components/TopBottomSection.vue'
import SideBySideSection from '@/components/SideBySideSection.vue'
import WindowSection from '@/components/WindowSection.vue'
import ContactForm from '@/components/ContactForm.vue'

@Component({
  components: {
    Offices,
    ListingCardCycler,
    LinkButton,
    NavigationLink,
    GoogleReviews,
    TopBottomSection,
    SideBySideSection,
    WindowSection,
    ContactForm,
  },
})
export default class Home extends Mixins(View) {
  public office!: Office

  public testimonials: Testimonial[] | null = null;

  public salesListings: Listing[] | null = null;

  public rentalsListings: Listing[] | null = null;

  public testimonialFilter: TestimonialFilter = new TestimonialFilter({
    SearchLevel: WebsiteLevel.Office,
    SearchGuid: Config.Website.Settings!.WebsiteId,
  });

  public listingSalesFilter: ListingFilter = new ListingFilter({
    Categories: [ListingCategory.ResidentialSale, ListingCategory.ResidentialLand, ListingCategory.Commercial, ListingCategory.CommercialLand, ListingCategory.Business],
    Statuses: [ListingStatus.Current, ListingStatus.UnderContract],
    IsFeatured: true,
    PageSize: 6,
  });

  public listingRentalsFilter: ListingFilter = new ListingFilter({
    Categories: [ListingCategory.ResidentialRental],
    Statuses: [ListingStatus.Current, ListingStatus.UnderContract],
    PageSize: 6,
  });

  mounted() {
    API.Offices.Get(Config.Website.Settings!.WebsiteId).then((office) => {
      this.office = office
    })

    // this.loadTestimonials();
    this.loadSalesListings()
    this.loadRentalsListings()

    let scr: HTMLScriptElement | null = document.getElementById('scrCoreLogicIngegration') as HTMLScriptElement | null
    if (scr) scr.remove()

    setTimeout(() => {
      scr = document.createElement('script')
      scr.src = 'https://dpr.leadplus.com.au/main.js'
      scr.async = true
      scr.defer = true
      scr.id = 'scrCoreLogicIngegration'

      scr.onload = () => {
        console.log('Core Logic Integration Loaded')
      }

      document.body.append(scr)
    }, 500)
  }

  loadTestimonials(): void {
    API.Testimonials.Search(this.testimonialFilter).then((testimonials) => {
      this.testimonials = testimonials
    })
  }

  loadSalesListings(): void {
    API.Listings.Search(this.listingSalesFilter).then((listings) => {
      this.salesListings = listings.Items
    })
  }

  loadRentalsListings(): void {
    API.Listings.Search(this.listingRentalsFilter).then((listings) => {
      this.rentalsListings = listings.Items
    })
  }
}
